/**
 * @flow
 */

import React, { useState, useContext } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { ArtistYearContext } from "../Home/Artists/context"

import PAST_ARTISTS_DATA from "./data"
import ARTISTS_DATA from "../Home/Artists/data"
import ImageWrapper from "../elements/imageWrapper"
import media from "../../styles/media"
import InstaLogo from "../../images/svg/instagram-logo.svg"
import MailIcon from "../../images/svg/mail-icon.svg"

// eslint-disable-next-line no-unused-vars
// type Props = {
//   updatedYear: String,
//   updateYear: ReactNode,
// }

const Container = styled.div`
  padding: var(--gutter-s);
  overflow-x: hidden;
  ${media.tablet`
    padding: var(--gutter-l);
  `}
`

const TableContainer = styled.div`
  font-size: 0.8rem;
  font-family: "circular-std-black";
  width: 100vw;
  margin-top: 2em;
  table {
    width: 90vw;
    tr {
      &.headings {
        font-size: 1.8em;
        font-family: "circular-std-bold";
        opacity: 0.4;
      }
    }
    td {
      padding: 1em 1em 0 0;
    }
    img {
      width: 15px;
      height: 15px;
      fill: black;
      &:hover {
        color: var(--carnation);
      }
    }
  }
  ${media.tablet`
    font-size: 0.9rem;
    table {
      width: 85vw;
      td {
        padding: 1em 0;
      }
    }
  `}
  ${media.laptop`
    font-size: 0.9rem;
    table {
      width: 90vw;
      td {
        padding: 1em 0;
      }
    }
  `}
`

const GridContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  ${media.tablet`
    grid-template-columns: 1fr 1fr;
  `}
  ${media.laptop`
    grid-template-columns: 1fr 1fr 1fr;
  `}
`

const Heading = styled.h1`
  font-weight: 600;
  font-family: "circular-std-black";
  text-align: center;
  font-size: 3rem;
  margin: 0;
  ${media.tablet`
    position: relative;
    text-align: left;
    transform: translateY(35%);
    font-size: 6.5rem;
    z-index: 2;
  `}
`

const PastArtistSelector = styled.div`
  display: flex;
  justify-content: center;
  /* position: relative; */
  vertical-align: center;
  z-index: 2;
  padding-top: 2em;
  p {
    font-size: 0.9rem;
  }
  p,
  button {
    margin-left: 5px;
  }
  button {
    z-index: 3;
    font-size: 0.9em;
    font-family: "circular-std-medium";
    padding: 0.1rem;
    margin: 0 0.5rem;
    margin-top: -0.2em;
    border: none;
    background-color: transparent;
    display: inline-block;
    position: relative;
    transition: all 250ms ease-out;
    &:focus {
      outline: none;
    }
    &:after {
      content: "";
      position: absolute;
      background-color: rgba(0, 0, 0, 1);
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
    }
    &.active {
      color: var(--carnation);
      &:after {
        display: none;
      }
    }
    &:hover {
      color: var(--carnation);
    }
  }
  .label {
    margin-right: 5px;
  }
  ${media.tablet`
    justify-content: flex-end;
    padding: 0;
    padding-right: 3rem;
  `}
`

const ArtistCitySelector = styled.div`
  display: flex;
  justify-content: center;
  /* position: relative; */
  vertical-align: center;
  z-index: 2;
  padding-top: 0.5em;
  p {
    font-size: 0.9rem;
  }
  p,
  button {
    margin-left: 5px;
  }
  button {
    z-index: 3;
    font-size: 0.9em;
    font-family: "circular-std-medium";
    padding: 0.1rem;
    margin: 0 0.5rem;
    margin-top: -0.2em;
    border: none;
    background-color: transparent;
    display: inline-block;
    position: relative;
    transition: all 250ms ease-out;
    &:focus {
      outline: none;
    }
    &:after {
      content: "";
      position: absolute;
      background-color: rgba(0, 0, 0, 1);
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
    }
    &.active {
      color: var(--carnation);
      &:after {
        display: none;
      }
    }
    &:hover {
      color: var(--carnation);
    }
  }
  .label {
    margin-right: 5px;
  }
  ${media.tablet`
    justify-content: flex-end;
    padding-right: 3rem;
  `}
`

const ArtistContainer = styled.div`
  font-size: 0.9rem;
  margin-top: 0.9rem;
  .grid {
    display: grid;
    grid-template-columns: 90% 10%;
    position: relative;
    p {
      writing-mode: vertical-rl;
      -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: vertical-rl;
      transform: scale(-1);
      text-align: right;
      padding-right: 5px;
    }
  }
  .gatsby-image-wrapper {
    aspect-ratio: 1;
    object-fit: contain;
  }
  .flex {
    display: flex;
    align-items: center;
    &.spacing {
      margin-top: 0.5rem;
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      margin-top: 1px;
    }
    .mail-img {
      height: 20px;
      width: 20px;
      margin-right: 0.5rem;
      margin-top: 4px;
    }
  }
  ${media.tablet`
    font-size: 0.9rem;
    .gatsby-image-wrapper {
      img {
        transition: transform 2500ms ease-out !important;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  `}
`
const GetATattoo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  a {
    font-size: 1.25rem;
    .link-primary {
      position: relative;
      color: var(--carnation);
      overflow: hidden;
      display: inline-block;
      &:after {
        content: "";
        position: absolute;
        background-color: var(--carnation);
        width: 100%;
        left: 0;
        bottom: 0;
        height: 2px;
        transition: all 250ms ease-out;
        transform: translateX(0%);
      }
      &:hover {
        &:after {
          transform: translateX(50%);
        }
      }
    }
  }
  ${media.tablet`
    .right-side {
      margin-left: auto;
    }
  `}
`

const ArtistText = styled.h1`
    padding-top: 3em;
`

const Artists = () => {
  const context = useContext(ArtistYearContext)
  const [year, setYear] = useState(context.updatedYear)
  const [city, setCity] = useState("Cape Town")

  const renderArtistData = () => {
    if (year != "2019" && year != "2020" && year != "2023" && year != "2024") {
      return PAST_ARTISTS_DATA.filter(artist => {
        return artist.year === year
      }).map((artist, index) => {
        const { name, shop, insta_handle, location } = artist
        return (
          <tr key={index}>
            <td>{name}</td>
            <td>{shop}</td>
            <td>{location}</td>
            <td>
              {insta_handle && (
                <a
                  className="mouse-link"
                  href={`https://instagram.com/${insta_handle}`}
                  target="blank"
                >
                  <img src={InstaLogo} />
                </a>
              )}
            </td>
          </tr>
        )
      })
    }
    if (year == "2019") {
      return PAST_ARTISTS_DATA.filter(artist => {
        return artist.year === year
      }).map((artist, index) => {
        const { name, img, insta_handle, location } = artist
        return (
          <ArtistContainer key={index}>
            <div className="grid">
              <ImageWrapper src={img} />
              <p> {location} </p>
            </div>
            <a
              className="mouse-link"
              href={`https://instagram.com/${insta_handle}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex spacing">
                <svg viewBox="0 0 24 24">
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
                <p>{name}</p>
              </div>
            </a>
          </ArtistContainer>
        )
      })
    }
    if (year == "2020") {
      return PAST_ARTISTS_DATA.filter(artist => {
        return artist.year === year
      }).map((artist, index) => {
        const { name, img, insta_handle, location, email_address } = artist
        return (
          <ArtistContainer key={index}>
            <div className="grid">
              <ImageWrapper src={img} />
              <p> {location} </p>
            </div>
            <div className="flex spacing">
              {email_address != '' && (
                <a className="mouse-link" href={`mailto:${email_address}`}>
                  <img src={MailIcon} className="mail-img" />
                </a>
              )}
              <a
                className="mouse-link"
                href={`https://instagram.com/${insta_handle}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex">
                  <svg viewBox="0 0 24 24">
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                  <p>{name}</p>
                </div>
              </a>
            </div>
          </ArtistContainer>
        )
      })
    }
    let error = false;
    if (year == "2023" || year == "2024") {
      return ARTISTS_DATA.filter(artist => {
        return artist.year === year && artist.cities_attending.includes(city) && artist.img != ''
      }).map((artist, index) => {
        const { name, img, insta_handle, location, email_address } = artist
        return (
          <ArtistContainer key={index}>
            <div className="grid">
              <ImageWrapper src={img} />
              <p> {location} </p>
            </div>
            <div className="flex spacing">
              {email_address && (
                <a className="mouse-link" href={`mailto:${email_address}`}>
                  <img src={MailIcon} className="mail-img" />
                </a>
              )}
              <a
                className="mouse-link"
                href={`https://instagram.com/${insta_handle}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex">
                  <svg viewBox="0 0 24 24">
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                  <p>{name}</p>
                </div>
              </a>
            </div>
          </ArtistContainer>
        )
      })
    }
  }

  return (
    <Container>
      <GetATattoo>
        <Link to="/getting-tattooed" className="mouse-link right-side">
          <span className="link-primary">how to get tattooed&#8594;</span>
        </Link>
      </GetATattoo>
      <Heading>{year}</Heading>
      <PastArtistSelector>
        <p className="label">artists </p>
        <button
          onClick={e => setYear(e.target.value)}
          value="2024"
          className={year === "2024" ? "active mouse-link" : "mouse-link"}
        >
          2024
        </button>
        <p>/</p>
        <button
          onClick={e => setYear(e.target.value)}
          value="2023"
          className={year === "2023" ? "active mouse-link" : "mouse-link"}
        >
          2023
        </button>
        <p>/</p>
        <button
          onClick={e => setYear(e.target.value)}
          value="2020"
          className={year === "2020" ? "active mouse-link" : "mouse-link"}
        >
          2020
        </button>
        <p>/</p>
        <button
          onClick={e => setYear(e.target.value)}
          value="2019"
          className={year === "2019" ? "active mouse-link" : "mouse-link"}
        >
          2019
        </button>
        <p>/</p>
        <button
          onClick={e => setYear(e.target.value)}
          value="2018"
          className={year === "2018" ? "active mouse-link" : "mouse-link"}
        >
          2018
        </button>
        <p>/</p>
        <button
          onClick={e => setYear(e.target.value)}
          value="2017"
          className={year === "2017" ? "active mouse-link" : "mouse-link"}
        >
          2017
        </button>
      </PastArtistSelector>
      {year == "2023" || year == "2024" && (
        <ArtistCitySelector>
          <button
            onClick={e => setCity(e.target.value)}
            value="Joburg"
            className={city === "Joburg" ? "active mouse-link" : "mouse-link"}
          >
            JHB
          </button>
          <p>/</p>
          <button
            onClick={e => setCity(e.target.value)}
            value="Cape Town"
            className={
              city === "Cape Town" ? "active mouse-link" : "mouse-link"
            }
          >
            CPT
          </button>
        </ArtistCitySelector>
      )}
      {year == "2017" && (
        <TableContainer>
          <table>
            <tbody>
              <tr className="headings">
                <td>name</td>
                <td>shop</td>
                <td>country</td>
              </tr>
              {renderArtistData()}
            </tbody>
          </table>
        </TableContainer>
      )}
      {year == "2018" && (
        <TableContainer>
          <table>
            <tbody>
              <tr className="headings">
                <td>name</td>
                <td>shop</td>
                <td>country</td>
              </tr>
              {renderArtistData()}
            </tbody>
          </table>
        </TableContainer>
      )}
      {(year == "2024" && city == "Joburg") && (
        <ArtistText>
          No artists available, the SAITC will only return to Johannesburg for 2025
        </ArtistText>
      )}
      {(year == "2024" && city == "Cape Town") && (
        <GridContainer>{renderArtistData()}</GridContainer>
      )}
      {["2019", "2020", "2023"].includes(year) && (
        <GridContainer>{renderArtistData()}</GridContainer>
      )}
    </Container>
  )
}

export default Artists
