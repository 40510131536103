const PAST_ARTISTS_DATA = [
  // 2017
  {
    name: "Aaron Carmody",
    shop: "Halo Tattoo",
    location: "Syracuse, New York",
    insta_handle: "rad_el_snake",
    year: "2017",
  },
  {
    name: "Austin Maples ",
    shop: "Idle Hand",
    location: "San Francisco, California",
    insta_handle: "austinmaples",
    year: "2017",
  },
  {
    name: "Alex Muller",
    shop: "The Body Architects",
    location: "Cape Town, SA",
    insta_handle: "weskuswolf",
    year: "2017",
  },
  {
    name: "Andy Felty",
    shop: "Suffer City",
    location: "Dallas Texas",
    insta_handle: "andyryanfelty",
    year: "2017",
  },
  {
    name: "Amanda Wachob",
    shop: "Amanda Wachob",
    location: "New York, US",
    insta_handle: "amandawachob",
    year: "2017",
  },
  {
    name: "Amy Unalome",
    shop: "Empire",
    location: "Melbourne, Australia",
    insta_handle: "amy_unalome_tattoo",
    year: "2017",
  },
  {
    name: "Bryce Oprandi",
    shop: "Martlett Tattoo",
    location: "Los Angeles, US",
    insta_handle: "bryceoprandi",
    year: "2017",
  },
  {
    name: "Ben Mcqueen",
    shop: "Martlett Tattoo",
    location: "Los Angeles, US",
    insta_handle: "benmcqueentattoo",
    year: "2017",
  },
  {
    name: "Byron Barker",
    shop: "Wildfire Tattoo",
    location: "Cape Town, SA",
    insta_handle: "byronbarkertattoos",
    year: "2017",
  },
  {
    name: "Chris De Villiers",
    shop: "The Body Architects",
    location: "Cape Town, SA",
    insta_handle: "chrisd3viltattoos",
    year: "2017",
  },
  {
    name: "Corey Crowley",
    shop: "Atlas Tattoo",
    location: "Portland Oregon",
    insta_handle: "coreycrowley",
    year: "2017",
  },
  {
    name: "Cory Schofield",
    shop: "Mainstay Tattoo",
    location: "Austin, Texas",
    insta_handle: "c_schofield_tattoo",
    year: "2017",
  },
  // {
  //   name: "Dan Gilsdorf",
  //   shop: "Atlas Tattoo",
  //   location: "Portland Oregon",
  //   insta_handle: "dangilsdorf",
  //   year: "2017",
  // },
  {
    name: "Daniel Gerbes",
    shop: "Suffer City",
    location: "Dallas Texas",
    insta_handle: "looming_moon",
    year: "2017",
  },
  {
    name: "Daniel Flower",
    shop: "Dublin Ink",
    location: "Dublin, Ireland",
    insta_handle: "danielflower",
    year: "2017",
  },
  {
    name: "David Chaston",
    shop: "Tomb Tattoo",
    location: "Cape Town, SA",
    insta_handle: "davidchastontattoo",
    year: "2017",
  },
  {
    name: "Demian Cervera",
    shop: "Fulcrum Tattoo",
    location: "London, Uk",
    insta_handle: "demiancervera",
    year: "2017",
  },
  {
    name: "Derick Montez",
    shop: "Idle Hand",
    location: "San Francisco, California",
    insta_handle: "derickmontez",
    year: "2017",
  },
  {
    name: "Dokter Irie",
    shop: "Trade Mark Tattoo",
    location: "Durban, SA",
    insta_handle: "dokter_irie",
  },
  {
    name: "Erica Flannes",
    shop: "Red Rocket Tattoo0",
    location: "New York, US",
    insta_handle: "ericaflannes",
    year: "2017",
  },
  {
    name: "Elric Gordon",
    shop: "Empire",
    location: "Melbourne, Australia",
    insta_handle: "elriccfh",
    year: "2017",
  },
  {
    name: "Gareth Lloyd",
    shop: "Cape Electric Tattoo",
    location: "Cape Town, SA",
    insta_handle: "garethlloyd.cet",
    year: "2017",
  },
  {
    name: "Hannah Wednesday",
    shop: "Tuesday Tattoo",
    location: "San Francisco, California",
    insta_handle: "hannahwednesday",
    year: "2017",
  },
  {
    name: "Holly Ellis",
    shop: "Idle Hand",
    location: "San Francisco, California",
    insta_handle: "hollyellistattoo",
    year: "2017",
  },
  {
    name: "Ina",
    shop: "VTS",
    location: "Göttingen",
    insta_handle: "inatattoos",
    year: "2017",
  },
  {
    name: "Ivan Antonyshev",
    shop: "Mainstay Tattoo",
    location: "Austin, Texas",
    insta_handle: "ivanantonyshevtattoo",
    year: "2017",
  },
  {
    name: "Jack",
    shop: "Starcrossed Tattoos",
    location: "Hong Kong",
    year: "2017",
  },
  {
    name: "Jarret Crosson",
    shop: "Royal Tiger Tattoo",
    location: "Austin, Texas",
    insta_handle: "jarretcrosson",
    year: "2017",
  },
  {
    name: "Jon James Case",
    shop: "The Body Architects",
    location: "Cape Town, SA",
    insta_handle: "jonjamecase",
    year: "2017",
  },
  {
    name: "Justin Spann",
    shop: "Electric Lounge Tattoos",
    location: "Shelly Beach, KZN",
    insta_handle: "nitsujtattooer",
    year: "2017",
  },
  {
    name: "Kady Wille",
    shop: "Ressurection Tattoo",
    location: "Austin, Texas",
    insta_handle: "kadywilie",
    year: "2017",
  },
  {
    name: "Katie Gray",
    shop: "One Shot Tattoo",
    location: "San Francisco, California",
    insta_handle: "___katie.gray",
    year: "2017",
  },
  {
    name: "Kim Deageon",
    shop: "Wild Rose",
    location: "Seoul, South Korea",
    year: "2017",
  },
  {
    name: "Luke Smith",
    shop: "Trade Mark Tattoo",
    location: "Durban, SA",
    insta_handle: "lukesmithtattoo",
    year: "2017",
  },
  {
    name: "Matt Cannon",
    shop: "Torch Tattoo",
    location: "Anaheim, US",
    insta_handle: "mattxcannon",
    year: "2017",
  },
  {
    name: "Matt Curzon",
    shop: "Empire",
    location: "Melbourne, Australia ",
    insta_handle: "mattcurzon",
    year: "2017",
  },
  {
    name: "Matthias Böttcher",
    shop: "Suffer City",
    location: "Berlin Germany",
    insta_handle: "matthiasboettcher",
    year: "2017",
  },
  {
    name: "Miguel Morales",
    shop: "M13 Tattoo",
    location: "Barcelona",
    insta_handle: "m13tattoo",
    year: "2017",
  },
  {
    name: "Michael Kam",
    shop: "Starcrossed Tattoos",
    location: "Hong Kong	",
    year: "2017",
  },
  {
    name: "Nathan Burton Phillips",
    shop: "Humdinger Tattoo Emporium",
    location: "Pretoria, SA",
    insta_handle: "travelsofnate",
    year: "2017",
  },
  {
    name: "Nic Lewis",
    shop: "Trade Mark Tattoo",
    location: "Durban, SA",
    insta_handle: "nic.fury",
    year: "2017",
  },
  {
    name: "Nicholas Mudskipper",
    shop: "Tomb Tattoo",
    location: "Cape Town, SA",
    insta_handle: "nicholasmudskipper",
    year: "2017",
  },
  {
    name: "Ninjabreadboy",
    shop: "Palm Black",
    location: "Cape Town, SA",
    insta_handle: "ninjabreadboy",
    year: "2017",
  },
  {
    name: "Park Kyu Hwan",
    shop: "Dumb Head Tattoo",
    location: "Seoul, South Korea",
    year: "2017",
  },
  {
    name: "Ralf Ostermeyer",
    shop: "The Sinner & The Saint",
    location: "Aachen Germany",
    insta_handle: "crewsyfixer",
    year: "2017",
  },
  {
    name: "Raoul Goetze",
    shop: "Palm Black",
    location: "Cape Town, SA",
    insta_handle: "raoulgoeyzetattoo",
    year: "2017",
  },
  {
    name: "Rich Phipson",
    shop: "Starcrossed Tattoos",
    location: "Hong Kong",
    insta_handle: "iconoglyphs",
    year: "2017",
  },
  {
    name: "Richie Howes",
    shop: "Electric Lounge Tattoos",
    location: "Shelly Beach, KZN",
    insta_handle: "richietattooer",
    year: "2017",
  },
  {
    name: "Ross Hallam",
    shop: "Handstyle Tattoos",
    location: "Johannesburg, SA",
    insta_handle: "rosshallam",
    year: "2017",
  },
  {
    name: "Ross Jones",
    shop: "Idle Hand",
    location: "San Francisco, California",
    insta_handle: "rosskjones",
    year: "2017",
  },
  // {
  //   name: "Ross Turpin",
  //   shop: "Starcrossed Tattoos",
  //   location: "Hong Kong",
  //   insta_handle: "ross_starcrossed",
  //   year: "2017",
  // },
  {
    name: "Ronald  Jacobs",
    shop: "Classic 1933 Tattoo",
    location: "Johannesburg, SA",
    insta_handle: "ronald1933tat2",
    year: "2017",
  },
  {
    name: "Rudolf Maartens",
    shop: "Bont & blauw",
    location: "Rotterdam, Netherlands",
    insta_handle: "om.karma.tattoo",
    year: "2017",
  },
  {
    name: "Rusty Knayles",
    shop: "Classic 1933 Tattoo",
    location: "Johannesburg, SA",
    insta_handle: "rastyknayles",
    year: "2017",
  },
  {
    name: "Ryan Metts",
    shop: "Greenpoint Tattoo Co.",
    location: "Brooklyn, NY",
    insta_handle: "ryanmettz",
    year: "2017",
  },
  {
    name: "Ryan Shaffer",
    shop: "Traveling",
    location: "Los Angeles, US",
    insta_handle: "ryanshaffer",
    year: "2017",
  },
  {
    name: "Shaun Nel",
    shop: "Atlas Tattoo",
    location: "Portland Oregon",
    insta_handle: "shauntheafrican",
    year: "2017",
  },
  // {
  //   name: "Tamar Thorn",
  //   shop: "The Body Architects",
  //   location: "Cape Town, SA",
  //   insta_handle: "tamarthorn",
  //   year: "2017",
  // },
  {
    name: "Travis Furton",
    shop: "The Martlett",
    location: "Los Angeles, US",
    insta_handle: "travisfurton",
    year: "2017",
  },
  {
    name: "Thomas Fernandes",
    shop: "Suffer City",
    location: "Dallas Texas",
    insta_handle: "t_fernandes_tattooer",
    year: "2017",
  },
  {
    name: "Tick",
    shop: "Black Rat Tattoo",
    location: "La Cruces, US",
    insta_handle: "ticktat2",
    year: "2017",
  },
  {
    name: "Waldo Del Rocca",
    shop: "Cape Electric Tattoo",
    location: "Cape Town, SA",
    insta_handle: "waldodelrocca",
    year: "2017",
  },
  {
    name: "Wan",
    shop: "Wild Rose",
    location: "Seoul, South Korea",
    insta_handle: "wan_tattooer",
    year: "2017",
  },
  {
    name: "Wagner Basei",
    shop: "Dublin Ink",
    location: "Dublin, Ireland",
    year: "2017",
  },
  {
    name: "Wesley Von Blerk",
    shop: "Handstyle Tattoos",
    location: "Johannesburg, SA",
    insta_handle: "wesley_von_blerk",
    year: "2017",
  },
  {
    name: "Willem Jansen",
    shop: "shop13",
    location: "Eindhoven, Germany",
    insta_handle: "willem.jansen.tattoo",
    year: "2017",
  },
  {
    name: "Zach Nelligan",
    shop: "Mainstay Tattoo",
    location: "Austin, Texas",
    insta_handle: "zachnelligan",
    year: "2017",
  },
  // 2018
  {
    name: "Alastair Magee",
    shop: "Dead Bird",
    location: "Germiston, South African",
    insta_handle: "alastairmagee",
    year: "2018",
  },
  {
    name: "Alex Muller",
    shop: "The Body Architects",
    location: "Cape Town, SA",
    insta_handle: "weskuswolf",
    year: "2018",
  },
  {
    name: "Aaron Carmody",
    shop: "Halo Tattoo",
    location: "Syracuse, New York",
    insta_handle: "rad_el_snake",
    year: "2018",
  },
  {
    name: "Andy Felty",
    shop: "Suffer City",
    location: "Dallas Texas",
    insta_handle: "andyryanfelty",
    year: "2018",
  },
  // {
  //   name: "Avalon Todaro",
  //   shop: "The Grand Illusion",
  //   location: "Melbourne, Australia",
  //   insta_handle: "avalontattoo",
  //   year: "2018",
  // },
  {
    name: "Byron Barker",
    shop: "The Body Architects",
    location: "Cape Town, SA",
    insta_handle: "byronbarkertattoos",
    year: "2018",
  },
  {
    name: "Chris De Villiers",
    shop: "Good Things Tattoo Co.",
    location: "Cape Town, SA",
    insta_handle: "chrisd3viltattoos",
    year: "2018",
  },
  {
    name: "Charl Steyn",
    shop: "Humdigger",
    location: "Joburg, SA",
    insta_handle: "charl_steyn_tattooing",
    year: "2018",
  },
  {
    name: "Corey Crowley",
    shop: "Atlas Tattoo",
    location: "Portland Oregon",
    insta_handle: "coreycrowley",
    year: "2018",
  },
  {
    name: "Cory Schofield",
    shop: "Mainstay Tattoo",
    location: "Austin, Texas",
    insta_handle: "c_schofield_tattoo",
    year: "2018",
  },
  // {
  //   name: "Dan Gilsdorf",
  //   shop: "Atlas Tattoo",
  //   location: "Portland Oregon",
  //   insta_handle: "dangilsdorf",
  //   year: "2018",
  // },
  {
    name: "Daniel Gerbes",
    shop: "Suffer City",
    location: "Dallas Texas",
    insta_handle: "looming_moon",
    year: "2018",
  },
  {
    name: "Daniel Flower",
    shop: "Dublin Ink",
    location: "Dublin, Ireland",
    insta_handle: "danielflower",
    year: "2018",
  },
  {
    name: "David Chaston",
    shop: "Palm Black Tattoo",
    location: "Cape Town, SA",
    insta_handle: "davidchastontattoo",
    year: "2018",
  },
  {
    name: "Davee Blows",
    shop: "hybrid ink seoul",
    location: "Poland",
    insta_handle: "davee._.lovesyou",
    year: "2018",
  },
  {
    name: "Dean Clarke",
    shop: "Tattoo Tony",
    location: "Pretoria, SA",
    insta_handle: "deanclarkeart",
    year: "2018",
  },
  {
    name: "Demian Cervera",
    shop: "Fulcrum Tattoo",
    location: "London, Uk",
    insta_handle: "demiancervera",
    year: "2018",
  },
  {
    name: "Dokter Irie",
    shop: "Cape Electric Tattoo",
    location: "Cape Town, SA",
    insta_handle: "dokter_irie",
    year: "2018",
  },
  {
    name: "Elric Gordon",
    shop: "Empire",
    location: "Melbourne, Australia",
    insta_handle: "elriccfh",
    year: "2018",
  },
  {
    name: "Gareth Lloyd",
    shop: "Cape Electric Tattoo",
    location: "Cape Town, SA",
    insta_handle: "garethlloyd.cet",
    year: "2018",
  },
  {
    name: "Gary Royal",
    shop: "Idle Hand",
    location: "San Francisco, California",
    insta_handle: "garyroyaltattoo",
    year: "2018",
  },
  {
    name: "Hannah Wednesday",
    shop: "Tuesday Tattoo",
    location: "San Francisco, California",
    insta_handle: "hannahwednesday",
    year: "2018",
  },
  {
    name: "Ina",
    shop: "VTS",
    location: "Göttingen",
    insta_handle: "inatattoos",
    year: "2018",
  },
  {
    name: "Ivan Antonyshev",
    shop: "Mainstay Tattoo",
    location: "Austin, Texas",
    insta_handle: "ivanantonyshevtattoo",
    year: "2018",
  },
  {
    name: "Jack Ciacomo Niccolai",
    shop: "Sailor Whipser Tattoo",
    location: "Ravenna,Italy",
    insta_handle: "jack_ciambo",
    year: "2018",
  },
  {
    name: "Jarret Crosson",
    shop: "Royal Tiger Tattoo",
    location: "Austin, Texas",
    insta_handle: "jarretcrosson",
    year: "2018",
  },
  {
    name: "Jenna Bouma",
    shop: "East River Tattoo",
    location: "Brooklyn, NY",
    insta_handle: "slowerblack",
    year: "2018",
  },
  {
    name: "Jon James Case",
    shop: "Good Things Tattoo Co.",
    location: "Cape Town, SA",
    insta_handle: "jonjamecase",
    year: "2018",
  },
  // {
  //   name: "Josh Todaro",
  //   shop: "The Grand Illusion",
  //   location: "Melbourne, Australia",
  //   insta_handle: "josh_todaro",
  //   year: "2018",
  // },
  {
    name: "Justin Spann",
    shop: "Electric Lounge Tattoos",
    location: "Shelly Beach, KZN",
    insta_handle: "nitsujtattooer",
    year: "2018",
  },
  {
    name: "Kady Wilie",
    shop: "Ressurection Tattoo",
    location: "Austin, Texas",
    insta_handle: "kadywilie",
    year: "2018",
  },
  {
    name: "Katie Gray",
    shop: "New York Adorned",
    location: "San Francisco, California",
    insta_handle: "___katie.gray",
    year: "2018",
  },
  {
    name: "Kim Deageon",
    shop: "Wild Rose",
    location: "Seoul, South Korea",
    year: "2018",
  },
  {
    name: "Katy Raymond",
    shop: "Iron Age",
    location: "St Louis",
    insta_handle: "katy_did_it",
    year: "2018",
  },
  {
    name: "Luke Smith",
    shop: "Trade Mark Tattoo",
    location: "Durban, SA",
    insta_handle: "lukesmithtattoo",
    year: "2018",
  },
  {
    name: "Matt Cannon",
    shop: "Torch Tattoo",
    location: "Anaheim, US",
    insta_handle: "mattxcannon",
    year: "2018",
  },
  {
    name: "Mav Mess",
    shop: "Atlas Tattoo",
    location: "Portland Oregon",
    insta_handle: "mavmess",
    year: "2018",
  },
  {
    name: "Max May",
    shop: "Travler",
    location: "USA",
    insta_handle: "maxmaytattoo",
    year: "2018",
  },
  {
    name: "Matthias Böttcher",
    shop: "Suffer City",
    location: "berlin Germany",
    insta_handle: "matthiasboettcher",
    year: "2018",
  },
  {
    name: "Miguel Morales",
    shop: "M13 Tattoo",
    location: "Barcelona",
    insta_handle: "m13tattoo",
    year: "2018",
  },
  {
    name: "Michael Kam",
    shop: "Starcrossed Tattoos",
    location: "Hong Kong",
    year: "2018",
  },
  {
    name: "Nathan Burton Phillips",
    shop: "Humdinger Tattoo Emporium",
    location: "Pretoria, SA",
    insta_handle: "travelsofnate",
    year: "2018",
  },
  {
    name: "Nic Lewis",
    shop: "Trade Mark Tattoo",
    location: "Durban, SA",
    insta_handle: "nic.fury",
    year: "2018",
  },
  {
    name: "Nicholas Mudskipper",
    shop: "Tomb Tattoo",
    location: "Cape Town, SA",
    insta_handle: "nicholasmudskipper",
    year: "2018",
  },
  {
    name: "Ninjabreadboy",
    shop: "Palm Black",
    location: "Cape Town, SA",
    insta_handle: "ninjabreadboy",
    year: "2018",
  },
  {
    name: "Ralf ' Osti' Ostermoller",
    shop: "The Sinner and the Saint",
    location: "Germany",
    insta_handle: "crewsyfixer",
    year: "2018",
  },
  {
    name: "Raoul Goetze",
    shop: "Palm Black",
    location: "Cape Town, SA",
    insta_handle: "raoulgoeyzetattoo",
    year: "2018",
  },
  {
    name: "Rich Phipson",
    shop: "Starcrossed Tattoos",
    location: "Hong Kong",
    insta_handle: "iconoglyphs",
    year: "2018",
  },
  {
    name: "Richie Howes",
    shop: "Electric Lounge Tattoos",
    location: "Shelly Beach, KZN",
    insta_handle: "richietattooer",
    year: "2018",
  },
  {
    name: "Ross Hallam",
    shop: "Handstyle Tattoos",
    location: "Johannesburg, SA",
    insta_handle: "rosshallam",
    year: "2018",
  },
  {
    name: "Ross Turpin",
    shop: "Starcrossed Tattoos",
    location: "Hong Kong",
    insta_handle: "ross_starcrossed",
    year: "2018",
  },
  {
    name: "Ronald  Jacobs",
    shop: "Classic 1933 Tattoo",
    location: "Johannesburg, SA",
    insta_handle: "ronald1933tat2",
    year: "2018",
  },
  {
    name: "Rudolf Maartens",
    shop: "Bont & blauw",
    location: "Rotterdam, Netherlands",
    insta_handle: "om.karma.tattoo",
    year: "2018",
  },
  {
    name: "Rusty Knayles",
    shop: "Classic 1933 Tattoo",
    location: "Johannesburg, SA",
    insta_handle: "rastyknayles",
    year: "2018",
  },
  {
    name: "Ryan Metts",
    shop: "Greenpoint Tattoo Co.",
    location: "Brooklyn, NY",
    insta_handle: "ryanmettz",
    year: "2018",
  },
  {
    name: "Ryan Shaffer",
    shop: "Traveling",
    location: "Los Angeles, US",
    insta_handle: "ryanshaffer",
    year: "2018",
  },
  {
    name: "Shaun Nel",
    shop: "Atlas Tattoo",
    location: "Portland Oregon",
    insta_handle: "shauntheafrican",
    year: "2018",
  },
  {
    name: "Sean Lewis",
    shop: "Tattoo Hub",
    location: "Johannesburg, SA",
    insta_handle: "seanlewis_13",
    year: "2018",
  },
  {
    name: "Tamar Thorn",
    shop: "Good Things Tattoo Co.",
    location: "Cape Town, SA",
    insta_handle: "tamarthorn",
    year: "2018",
  },
  {
    name: "Tony Weintraud",
    shop: "Tattoo Tony",
    location: "Pretoria, SA",
    insta_handle: "onetattootony",
    year: "2018",
  },
  {
    name: "Thomas Fernandes",
    shop: "Suffer City",
    location: "Dallas Texas",
    insta_handle: "t_fernandes_tattooer",
    year: "2018",
  },
  {
    name: "Tick",
    shop: "Black Rat Tattoo",
    location: "La Cruces, US",
    insta_handle: "ticktat2",
    year: "2018",
  },
  {
    name: "Waldo Del Rocca",
    shop: "Cape Electric Tattoo",
    location: "Cape Town, SA",
    insta_handle: "waldodelrocca",
    year: "2018",
  },
  {
    name: "Wan",
    shop: "Wild Rose",
    location: "Seoul, South Korea",
    insta_handle: "wan_tattooer",
    year: "2018",
  },
  {
    name: "Wagner Basei",
    shop: "Dublin Ink",
    location: "Dublin, Ireland",
    insta_handle: "wagnerbasei",
    year: "2018",
  },
  {
    name: "Wesley Von Blerk",
    shop: "Palm Black Tattoo",
    location: "Cape Town, SA",
    // insta_handle: "wan_tattooer",
    year: "2018",
  },
  {
    name: "Willem Jansen",
    shop: "shop13",
    location: "Einthoven, Netherlands",
    insta_handle: "willem.jansen.tattoo",
    year: "2018",
  },
  {
    name: "Zach Nelligan",
    shop: "Mainstay Tattoo",
    location: "Austin, Texas",
    insta_handle: "zachnelligan",
    year: "2018",
  },
  {
    name: "Duan Woo",
    shop: "Sick Rose Tattoo",
    location: "Shanghai, China",
    insta_handle: "duan.tattooer",
    year: "2018",
  },
  {
    name: "Nate Hudak",
    shop: "Crying Heart",
    location: "Cincinnati, USA",
    insta_handle: "nate_hudak",
    year: "2018",
  },
  {
    name: "Dave Halsey",
    shop: "Crying Heart",
    location: "Cincinnati, USA",
    insta_handle: "davehalseytattoos",
    year: "2018",
  },
  // {
  //   name: "Tyler Abnor",
  //   shop: "Crying Heart",
  //   location: "Cincinnati, USA",
  //   insta_handle: "tylerabnertattoo",
  //   year: "2018",
  // },
  {
    name: "Dan Rick",
    shop: "Big Top tattoo",
    location: "Michigan, USA",
    insta_handle: "danrick",
    year: "2018",
  },
  {
    name: "Jean le Roux",
    shop: "Black Garden Tattoo",
    location: "London, UK",
    insta_handle: "jeanleroux",
    year: "2018",
  },
  {
    name: "Linus O'malley",
    shop: "Blacklist Tattoo",
    location: "Portland Oregon",
    insta_handle: "linus_omalley",
    year: "2018",
  },
  {
    name: "Tanner Ramsey",
    shop: "Ceremony Tattoo",
    location: "Ventura,CA",
    insta_handle: "tannerramsey",
    year: "2018",
  },
  {
    name: "John Hill",
    shop: "Consolidated tattoo",
    location: "Flint, Michegan",
    insta_handle: "johnhilltattoo",
    year: "2018",
  },
  {
    name: "Andy Canino",
    shop: "Dedication tattoo",
    location: "Denver,Colorado",
    insta_handle: "a_canino",
    year: "2018",
  },
  {
    name: "Lars van Zandt",
    shop: "Iron Tiger Tattoo",
    location: "Columbia, Missouri",
    insta_handle: "lars.van.zandt",
    year: "2018",
  },
  {
    name: "Ashley Welman",
    shop: "Trade Mark Tattoo",
    location: "Durban,SA",
    insta_handle: "el_duderino87",
    year: "2018",
  },
  {
    name: "Morris Guth",
    shop: "Sinner and the Saint",
    location: "Aachen,Germany",
    insta_handle: "morrisguth",
    year: "2018",
  },
  {
    name: "Kai Soong",
    shop: "Sick Rose Tattoo",
    location: "Shanghai, China",
    insta_handle: "kai.tattooer",
    year: "2018",
  },
  {
    name: "David Pollen",
    shop: "Sick Rose Tattoo",
    location: "Shanghai, China",
    insta_handle: "azndave",
    year: "2018",
  },
  {
    name: "Horikasiwa",
    location: "Taiwan, China",
    insta_handle: "horikasiwa",
    year: "2018",
  },
  {
    name: "Zaga kenny",
    location: "Taiwan, China",
    insta_handle: "zagakenny",
    year: "2018",
  },

  // 2019
  {
    name: "Aaron Carmody",
    shop: "Halo Tattoo",
    location: "Syracuse, New York, USA",
    insta_handle: "rad_el_snake",
    year: "2019",
    img: "artists/2019-Artists/Aaron-Carmody.jpg",
  },
  // {
  //   name: "Alastair Magee",
  //   shop: "DeadBird Tattoos",
  //   location: "Pretoria, SA",
  //   insta_handle: "alastairmagee",
  //   year: "2019",
  //   img: "artists/2019-Artists/Alastair Magee.jpg",
  // },
  {
    name: "Alberto Lelli",
    shop: "Horror Vacui Tattoo",
    location: "Bologna, Italy",
    insta_handle: "albertinodabologna",
    year: "2019",
    img: "artists/2019-Artists/Alberto-Lelli.jpg",
  },
  // {
  //   name: "Alessio Plizzari",
  //   shop: "The Black Pearl Tattoo Parlour",
  //   location: "Milan, Italy",
  //   insta_handle: "pliz_lpn",
  //   year: "2019",
  //   img: "artists/2019-Artists/Alessio Plizzari.jpg",
  // },
  {
    name: "Alex Muller",
    shop: "The Body Architects",
    location: "Cape Town , SA",
    insta_handle: "weskuswolf",
    year: "2019",
    img: "artists/2019-Artists/Alex-Muller.jpg",
  },
  {
    name: "Alex Travers",
    shop: "Cape Electric",
    location: "London , UK",
    insta_handle: "allybonestattoo",
    year: "2019",
    img: "artists/2019-Artists/Alex-Travers.jpg",
  },
  {
    name: "Alice Carrier",
    shop: "Lovebird Tattoo",
    location: "Portland Oregon, USA",
    insta_handle: "alicerules",
    year: "2019",
    img: "artists/2019-Artists/Alice-Carrier.jpg",
  },
  {
    name: "Andrew Vidakovich",
    shop: "Stay Humble",
    location: "Baltimore, USA",
    insta_handle: "andrewvidakovich",
    year: "2019",
    img: "artists/2019-Artists/Andrew-Vidakovich.jpg",
  },
  {
    name: "Anna Claire Whysall",
    shop: "Trade Mark Tattoo",
    location: "Durban, SA",
    insta_handle: "tigertitz",
    year: "2019",
    img: "artists/2019-Artists/Anna-Claire-Whysall.jpg",
  },
  {
    name: "Ashley Welman",
    shop: "Trade Mark Tattoo",
    location: "Durban, SA",
    insta_handle: "el_duderino87",
    year: "2019",
    img: "artists/2019-Artists/Ashley-Welman.jpg",
  },
  {
    name: "Augusto De Filippo",
    shop: "Skull and Bones Tattoo",
    location: "Rome, Italy",
    insta_handle: "augustodefilippo",
    year: "2019",
    img: "artists/2019-Artists/Augusto-De-Flippo.jpg",
  },
  {
    name: "Auke Ponne",
    shop: "True Blue Tattoo",
    location: "Eindhoven, Netherlands",
    insta_handle: "aukextattooer",
    year: "2019",
    img: "artists/2019-Artists/Auke-Ponne.jpg",
  },
  {
    name: "Bert Raymakers",
    shop: "Dagger Moon Tattoo",
    location: "Hasselt, Belgium",
    insta_handle: "bertdaggermoon",
    year: "2019",
    img: "artists/2019-Artists/Bert-Raymakers.jpg",
  },
  {
    name: "Bob Geerts ",
    shop: "True Blue Tattoo",
    location: "Eindhoven, Netherlands",
    insta_handle: "bobeustattoo",
    year: "2019",
    img: "artists/2019-Artists/Bob-Beerts.jpg",
  },
  {
    name: "Byron Barker",
    shop: "The Body Architects",
    location: "Cape Town , SA",
    insta_handle: "byronbarkertattoos",
    year: "2019",
    img: "artists/2019-Artists/Byron-Barker.jpg",
  },
  {
    name: "Chris De Villiers",
    shop: "Good Things Tattoo Co.",
    location: "Cape Town, SA",
    insta_handle: "chrisd3viltattoos",
    year: "2019",
    img: "artists/2019-Artists/Chris-De-Villiers.jpg",
  },
  {
    name: "Clemens Hahn",
    shop: "Truly Yours Tattoo",
    location: "Mannheim,Germany",
    insta_handle: "clemens_hahn",
    year: "2019",
    img: "artists/2019-Artists/Clemens-Hahn.jpg",
  },
  // {
  //   name: "Dan Gilsdorf",
  //   shop: "Atlas Tattoo",
  //   location: "Portland Oregon, USA",
  //   insta_handle: "dangilsdorf",
  //   year: "2019",
  //   img: "artists/2019-Artists/Dan-Gilsdorf.jpg",
  // },
  {
    name: "Daniele Scafati",
    shop: "Skull and Bones Tattoo",
    location: "Rome, Italy",
    insta_handle: "danielescafati",
    year: "2019",
    img: "artists/2019-Artists/Daniele-Scafati.jpg",
  },
  {
    name: "Danne Tatowierer",
    shop: "Der Grimm ",
    location: "Berlin, Germany",
    insta_handle: ".tatowierer",
    year: "2019",
    img: "artists/2019-Artists/Danne-Tatowierer.jpg",
  },
  {
    name: "Darryl Hart",
    shop: "High Tides Tattoo",
    location: "New Brunswick, Canada",
    insta_handle: "dhart_tl",
    year: "2019",
    img: "artists/2019-Artists/Darryl-Hart.jpg",
  },
  {
    name: "David Chaston",
    shop: "Palm Black Tattoo Co.",
    location: "Cape Town, SA",
    insta_handle: "davidchastontattoo",
    year: "2019",
    img: "artists/2019-Artists/David-Chaston.jpg",
  },
  {
    name: "Dean Clarke",
    shop: "Tattoo Tony",
    location: "Pretoria, SA",
    insta_handle: "deanclarkeart",
    year: "2019",
    img: "artists/2019-Artists/Dean-Clarke.jpg",
  },
  {
    name: "Demetrio Genazzani",
    shop: "Demy Tattoo Parlour",
    location: "Milano, Italy",
    insta_handle: "demy_tattooer_",
    year: "2019",
    img: "artists/2019-Artists/Demetrio-Genazzani.jpg",
  },
  {
    name: "Dok",
    shop: "Cape Electric Tattoo",
    location: "Cape Town, SA",
    insta_handle: "dokter_irie",
    year: "2019",
    img: "artists/2019-Artists/Dok.jpg",
  },
  // {
  //   name: "Eddie Czaicki",
  //   shop: "23 Keller",
  //   location: "Belguim",
  //   insta_handle: "eddieczaicki",
  //   year: "2019",
  //   img: "artists/2019-Artists/Eddie-Czaicki.jpg",
  // },
  {
    name: "Elie Falcon",
    shop: "Sad Clown City",
    location: "Boston, USA",
    insta_handle: "prof_falcon",
    year: "2019",
    img: "artists/2019-Artists/Elie-Falcon.jpg",
  },
  {
    name: "Fernando Mardo",
    shop: "Dame of the West",
    location: "Arizona, USA",
    insta_handle: "fernofthewest",
    year: "2019",
    img: "artists/2019-Artists/Fernando-Mardo.jpg",
  },
  {
    name: "Gareth Lloyd",
    shop: "Cape Electric Tattoo",
    location: "Cape Town, SA",
    insta_handle: "garethlloyd.cet",
    year: "2019",
    img: "artists/2019-Artists/Gareth-Lloyd.jpg",
  },
  // {
  //   name: "Greg Whitehead",
  //   shop: "Atlas Tattoo",
  //   location: "Portland Oregon, USA",
  //   insta_handle: "greggletron",
  //   year: "2019",
  //   img: "artists/2019-Artists/Greg-Whitehead.jpg",
  // },
  {
    name: "Guus Mandigers",
    shop: "True Blue Tattoo",
    location: "Eindhoven, Netherlands",
    insta_handle: "mandigerstattoo",
    year: "2019",
    img: "artists/2019-Artists/Guus-Mandigers.jpg",
  },
  {
    name: "Igor Sarkanice",
    shop: "Berlin Ink Tattoo",
    location: "Berlin, Germany",
    insta_handle: "sarkanice",
    year: "2019",
    img: "artists/2019-Artists/Igor-sarkanice.jpg",
  },
  // {
  //   name: "Ivan Antonyshev",
  //   shop: "N/A",
  //   location: "USA",
  //   insta_handle: "ivanantonyshevtattoo",
  //   year: "2019",
  //   img: "artists/2019-Artists/Ivan-Antonyshev.jpg",
  // },
  {
    name: "Jamie Molin",
    shop: "Westside Tattoo",
    location: "Brisbane, Australia ",
    insta_handle: "jamiemolintattoo",
    year: "2019",
    img: "artists/2019-Artists/Jamie-Molin.jpg",
  },
  // {
  //   name: "Jenna Bouma",
  //   shop: "East River Tattoo",
  //   location: "Brooklyn, NY",
  //   insta_handle: "slowerblack",
  //   year: "2019",
  //   img: "artists/2019-Artists/Jenna-Bouma.jpg",
  // },
  {
    name: "Joe Spaven",
    shop: "Scarlet Rose Tattoo",
    location: "Newport Pagnell U.K ",
    insta_handle: "joe_scarletrose",
    year: "2019",
    img: "artists/2019-Artists/Joe-Spaven.jpg",
  },
  {
    name: "Johnathan Reina",
    location: "Gran Canaria, Spain",
    insta_handle: "jonathanreina",
    year: "2019",
    img: "artists/2019-Artists/Jonathan-Reina.jpg",
  },
  {
    name: "Johan Ostling",
    shop: "Queens Tattoo",
    location: "Gavle, Sweden",
    insta_handle: "johantattooing",
    year: "2019",
    img: "artists/2019-Artists/Johan-Osling.jpg",
  },
  // {
  //   name: "Jon James Case",
  //   shop: "Good Things Tattoo Co.",
  //   location: "Cape Town, SA",
  //   insta_handle: "jonjamescase",
  //   year: "2019",
  //   img: "artists/2019-Artists/Jon-James-Case.jpg",
  // },
  {
    name: "Katy Raymond",
    shop: "Iron Age",
    location: "St Louis",
    insta_handle: "katy_did_it",
    year: "2019",
    img: "artists/2019-Artists/Katy-Raymond.jpg",
  },
  {
    name: "Kawika Montoya",
    shop: "Goodlife Tattoo",
    location: "Mal Pais, Costa Rica",
    insta_handle: "k_montoya_tattoo",
    year: "2019",
    img: "artists/2019-Artists/Kawika-Montoya.jpg",
  },
  {
    name: "Lars van Zandt",
    shop: "Iron Tiger",
    location: "Columbia, Missouri, USA",
    insta_handle: "lars.van.zandt",
    year: "2019",
    img: "artists/2019-Artists/Lars-van-Zandt.jpg",
  },
  {
    name: "Lauren Peachfish",
    shop: "Fallen Heroes",
    location: "Joburg, SA",
    insta_handle: "laurenpeachfish",
    year: "2019",
    img: "artists/2019-Artists/Lauren-Peachfish.jpg",
  },
  // {
  //   name: "Linus Omalley",
  //   shop: "Blacklist Tattoo",
  //   location: "Portland Oregon, USA",
  //   insta_handle: "linus_omalley",
  //   year: "2019",
  //   img: "artists/2019-Artists/Linus-Omalley.jpg",
  // },
  // {
  //   name: "Luke Edgar",
  //   shop: "Immortal Ink",
  //   location: "London, UK",
  //   insta_handle: "luke_edgar",
  //   year: "2019",
  //   img: "artists/2019-Artists/Luke Edgar.jpg",
  // },
  {
    name: "Luke James Smith",
    shop: "Trade Mark Tattoo",
    location: "Durban, SA",
    insta_handle: "lukesmithtattoo",
    year: "2019",
    img: "artists/2019-Artists/Luke-James-Smith.jpg",
  },
  {
    name: "Luke Nicou",
    shop: "Otherworld Tattoo",
    location: "PE, South Africa",
    insta_handle: "luckyluketattooer",
    year: "2019",
    img: "artists/2019-Artists/Luke-Nicou.jpg",
  },
  {
    name: "Matt Cannon",
    shop: "Torch Tattoo",
    location: "Anaheim, US",
    insta_handle: "mattxcannon",
    year: "2019",
    img: "artists/2019-Artists/Matt-Cannon.jpg",
  },
  {
    name: "Matthew Oldfield",
    shop: "Palm Black Tattoo",
    location: "Cape Town, SA",
    insta_handle: "mattoldfield_tattoos",
    year: "2019",
    img: "artists/2019-Artists/Matt-Oldfield.jpg",
  },
  {
    name: "Mav Mess",
    shop: "Deluxe Tattoo",
    location: "Portland Oregon, USA",
    insta_handle: "mavmess",
    year: "2019",
    img: "artists/2019-Artists/Mav-Mess.jpg",
  },
  // {
  //   name: "Max May",
  //   shop: "Dame of the West",
  //   location: "USA",
  //   insta_handle: "maxmaytattoo",
  //   year: "2019",
  //   img: "artists/2019-Artists/Max-May.jpg",
  // },
  {
    name: "Melanie No",
    shop: "Inky und Stretchy",
    location: "Frankfurt, Germany",
    insta_handle: "_melanie_no_",
    year: "2019",
    img: "artists/2019-Artists/Melanie-No.jpg",
  },
  {
    name: "Michele Cafiero",
    shop: "Skull and Bones Tattoo",
    location: "Rome, Italy",
    insta_handle: "micidialetattoo",
    year: "2019",
    img: "artists/2019-Artists/Michele-Cafiero.jpg",
  },
  {
    name: "Mo Ducommun",
    shop: "Il Gatto & La Volpe",
    location: "Lucerne, Switzerland",
    insta_handle: "moducommun",
    year: "2019",
    img: "artists/2019-Artists/Mo-Ducommun.jpg",
  },
  {
    name: "Monique La Cucaracha",
    shop: "Heartgame Tattoo",
    location: "Aarau, Switzerland",
    insta_handle: "moniquelacucaracha",
    year: "2019",
    img: "artists/2019-Artists/Monique-La-Cucaracha.jpg",
  },
  // {
  //   name: "Nate Hudak",
  //   shop: "Crying Heart",
  //   location: "Cincinnati, USA",
  //   insta_handle: "nate_hudak",
  //   year: "2019",
  //   img: "artists/2019-Artists/Nate-Hudak.jpg",
  // },
  {
    name: "Nic Lewis",
    shop: "Trade Mark Tattoo",
    location: "Durban, SA",
    insta_handle: "nic.fury",
    year: "2019",
    img: "artists/2019-Artists/Nic-Lewis.jpg",
  },
  {
    name: "Nicholas Mudskipper",
    shop: "Tomb Tattoo",
    location: "Cape Town, SA",
    insta_handle: "nicholasmudskipper",
    year: "2019",
    img: "artists/2019-Artists/Nicholas-Mudskipper.jpg",
  },
  {
    name: "Nico Acosta",
    shop: "Better Days",
    location: "Santiago, Chile",
    insta_handle: "nicoacostatattoos",
    year: "2019",
    img: "artists/2019-Artists/Nico-Acosta.jpg",
  },
  {
    name: "NinjaBreadBoy",
    shop: "Palm Black Tattoo Co.",
    location: "Cape Town, SA",
    insta_handle: "ninjabreadboy",
    year: "2019",
    img: "artists/2019-Artists/Ninja--Breadboy.jpg",
  },
  {
    name: "Paul Wolk",
    shop: "The Okey Doke Tattoo Shop",
    location: "Toronto, Canada",
    insta_handle: "paulwolk_tl",
    year: "2019",
    img: "artists/2019-Artists/Paul Wolk.jpg",
  },
  {
    name: "Pepi Dimevski",
    shop: "Sa Hardcore Social Club",
    location: "Joburg, SA",
    insta_handle: "sa_hardcore_socialclub",
    year: "2019",
    img: "artists/2019-Artists/Pepi-Dimevski.jpg",
  },
  {
    name: "Raoul Goetze",
    shop: "Palm Black Tattoo Co.",
    location: "Cape Town, SA",
    insta_handle: "raoulgoetze",
    year: "2019",
    img: "artists/2019-Artists/Raoul-Goetze.jpg",
  },
  {
    name: "Riccardo Berlanda",
    shop: "Red Couch Milan",
    location: "Milan",
    insta_handle: "berly.boy",
    year: "2019",
    img: "artists/2019-Artists/Riccardo-Berlanda.jpg",
  },
  {
    name: "Richie Howes",
    shop: "Electric Lounge Tattoos",
    location: "Shelly Beach, KZN",
    insta_handle: "richietattooer",
    year: "2019",
    img: "artists/2019-Artists/Richie-Howes.jpg",
  },
  {
    name: "Ross Hallam",
    shop: "Handstyle Tattoo",
    location: "Johannesburg, SA",
    insta_handle: "rosshallam",
    year: "2019",
    img: "artists/2019-Artists/Ross-Hallam.jpg",
  },
  {
    name: "Sarah Herzadame",
    shop: "Erntezeit Tattoo",
    location: "Berlin, Germany",
    insta_handle: "herzdame",
    year: "2019",
    img: "artists/2019-Artists/Sarah-Herzdame.jpg",
  },
  {
    name: "Sean Lanusse",
    shop: "Blacklist Tattoo",
    location: "Portland Oregon, USA",
    insta_handle: "sickradsean",
    year: "2019",
    img: "artists/2019-Artists/Sean-Lanusse.jpg",
  },
  {
    name: "Shaun Dean",
    shop: "Dark Horse",
    location: "Cape Town, SA",
    insta_handle: "shaunjdean",
    year: "2019",
    img: "artists/2019-Artists/Sean-Dean.jpg",
  },
  {
    name: "Squid Yu",
    location: "Taiwan",
    insta_handle: "squid_yu",
    year: "2019",
    img: "artists/2019-Artists/Squid-Yu.jpg",
  },
  {
    name: "Stefan Guntzel",
    shop: "SG Tattoo",
    location: "St. Gallen, Switzerland",
    insta_handle: "stefan_guentzel",
    year: "2019",
    img: "artists/2019-Artists/Stefan-Guntzel.jpg",
  },
  {
    name: "Tamar Thorn",
    shop: "Good Things Tattoo Co.",
    location: "Cape Town, SA",
    insta_handle: "tamarthorn",
    year: "2019",
    img: "artists/2019-Artists/Tamar-Thorn.jpg",
  },
  {
    name: "Tanner Ramsey",
    shop: "Ceremony Tattoo",
    location: "Brooklyn, New York, USA",
    insta_handle: "tannerramsey",
    year: "2019",
    img: "artists/2019-Artists/Tanner-Ramsey.jpg",
  },
  {
    name: "Taryn Nissen",
    shop: "Handstyle Tattoo",
    location: "JHB, SA",
    insta_handle: "tarynthetattooist",
    year: "2019",
    img: "artists/2019-Artists/Tarryn-Nissen.jpg",
  },
  {
    name: "Theunis Coetzee",
    shop: "Awhe Tattoo",
    location: "Johannesburg, SA",
    insta_handle: "theunisawhe187",
    year: "2019",
    img: "artists/2019-Artists/Theunis-Coetzee.jpg",
  },
  {
    name: "Thys Uys",
    shop: "Fallen Heroes",
    location: "Johannesburg, SA",
    insta_handle: "thys_uys",
    year: "2019",
    img: "artists/2019-Artists/Thys-Uys.jpg",
  },
  {
    name: "Tony Weintraud",
    shop: "Tattoo Tony",
    location: "Pretoria, SA",
    insta_handle: "onetattootony",
    year: "2019",
    img: "artists/2019-Artists/Tony-Weintraud.jpg",
  },
  {
    name: "Tyler Abner",
    shop: "Crying Heart",
    location: "Cincinnati, USA",
    insta_handle: "tylerabnertattoo",
    year: "2019",
    img: "artists/2019-Artists/Tyler-Abner.jpg",
  },
  {
    name: "Waldo Del Rocca",
    shop: "Cape Electric Tattoo",
    location: "Cape Town, SA",
    insta_handle: "waldodelrocca",
    year: "2019",
    img: "artists/2019-Artists/Waldo-Del-Rocca.jpg",
  },
  // {
  //   name: "Wesley Von Blerk",
  //   shop: "Palm Black Tattoo",
  //   location: "Cape Town, SA",
  //   insta_handle: "wesley_von_blerk",
  //   year: "2019",
  //   img: "artists/2019-Artists/Wesley-Von-Blerk.jpg",
  // },
  {
    name: "Willem Jansen",
    shop: "13",
    location: "Eindhoven, Netherlands",
    insta_handle: "willem.jansen.tattoo",
    year: "2019",
    img: "artists/2019-Artists/Willem-Jansen.jpg",
  },
  {
    name: "Yadou Trojanowska",
    shop: "Lost Wisdom",
    location: "Poland",
    insta_handle: "yadou_tattoo",
    year: "2019",
    img: "artists/2019-Artists/Yadou-Trojanowska.jpg",
  },
  {
    name: "Younes",
    shop: "Brabos hand tattoo",
    location: "Antwerp, Belgium",
    insta_handle: "younes_tattooer",
    year: "2019",
    img: "artists/2019-Artists/Younes.jpg",
  },
  {
    name: "Starabroda",
    shop: "Traveling ",
    location: "Traveling ",
    insta_handle: "starabroda",
    year: "2019",
    img: "artists/2019-Artists/Starabroda.jpg",
  },
  // 2020
  {
    name: "Aaron Carmody",
    img: "artists/2020-Artists/Aaron-Carmody.jpg",
    country: "USA",
    town: "New York",
    location: "Syracuse, New York",
    insta_handle: "rad_el_snake",
    email_address: "aaronnva@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  // 2020
  {
    name: "Adri O",
    img: "artists/2020-Artists/Adri-O.jpg",
    country: "Germany",
    town: "Erlanger",
    location: "Erlanger, Germany",
    insta_handle: "adri_olala",
    email_address: "info@tcnoire.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Alastair Magee",
    img: "artists/2020-Artists/Alastair-Magee.jpg",
    country: "South Africa",
    town: "Johannesburg",
    location: "Johannesburg, SA",
    insta_handle: "alastairmagee",
    email_address: "asta27@icloud.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Albertino",
    img: "artists/2020-Artists/Albertino.jpg",
    country: "Italy",
    town: "Bologna",
    location: "Bologna, Italy",
    insta_handle: "albertinodabologna",
    email_address: "albeyomann@hotmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Alex Muller",
    img: "artists/2020-Artists/Alex-Muller.jpg",
    country: "South Africa",
    town: "Cape Town",
    location: "Cape Town, SA",
    insta_handle: "weskuswolf",
    email_address: "weskuswolftattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Alex Steiger",
    img: "artists/2020-Artists/alex-steiger.jpg",
    country: "South Africa",
    town: "Cape Town",
    location: "Cape Town, SA",
    insta_handle: "alexsteiger",
    email_address: "alexsteigertattoos@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Alex Travers",
    img: "artists/2020-Artists/Alex-Travers.jpg",
    country: "United Kingdom",
    town: "London",
    location: "London, UK",
    insta_handle: "allybonestattoo",
    email_address: "allybonestattooing@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Andy Gibson",
    img: "artists/2020-Artists/Andy-Gibson.jpg",
    country: "Canada",
    town: "Ontario",
    location: "Ontario, Canada",
    insta_handle: "andythomasgibson",
    email_address: "andythomasgibson@live.com",
    // style: "Traditional",
    year: "2020",
  },
  // {
  //   name: "Andy van Rens",
  //   img: "artists/2020-Artists/Andy-van-Rens.jpg",
  //   country: "Netherlands",
  //   town: "Eindhoven",
  //   location: "Eindhoven, Netherlands",
  //   insta_handle: "andyvanrens",
  //   email_address: "info@trueblue-tattoo.com",
  //   // style: "Traditional",
  //   year: "2020",
  // },
  {
    name: "Anna-Claire Travers",
    img: "artists/2020-Artists/Anna-Claire-Whysall.jpg",
    country: "United Kingdom",
    town: "London",
    location: "London, UK",
    email_address: "annawhysall@gmail.com",
    // style: "Traditional",
    insta_handle: "tigertitz",
    year: "2020",
  },

  {
    name: "Ashley Welman",
    img: "artists/2020-Artists/Ashley-Welman.jpg",
    country: "South Africa",
    town: "Durban",
    location: "Durban, SA",
    insta_handle: "el_duderino87",
    email_address: "ash_famous@hotmail.co.uk",
    // style: "Traditional",
    year: "2020",
  },
  // {
  //   name: "Avalon Todaro",
  //   img: "artists/2020-Artists/Avalon-Todaro.jpg",
  //   country: "Australia",
  //   town: "Melbourne",
  //   location: "Melbourne, Australia",
  //   insta_handle: "avalontattoo",
  //   email_address: "avalonwestcott@gmail.com",
  //   // style: "Traditional",
  //   year: "2020",
  // },
  {
    name: "Benjamin Lazo Lee",
    img: "artists/2020-Artists/Benjamin-Lazo-Lee.jpg",
    country: "Taiwan",
    town: "Tapei",
    location: "Tapei, Taiwan",
    insta_handle: "benlazo.nn8",
    email_address: "nn8.tatz@gmial.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Berly Boy",
    img: "artists/2020-Artists/Berly-Boy.jpg",
    country: "Italy",
    town: "Milano",
    location: "Milano, Italy",
    insta_handle: "berly.boy",
    email_address: "berlyb666y@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Bert Raymaker",
    img: "artists/2020-Artists/Bert-Raymaker.jpg",
    country: "Belgium",
    town: "Hasselt",
    location: "Hasselt, Belgium",
    insta_handle: "bertdaggermoon",
    email_address: "bert.daggermoon@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Big Jaz",
    img: "artists/2020-Artists/Big-Jaz.jpg",
    country: "USA",
    town: "New York",
    location: "New York, USA",
    insta_handle: "bigdaddyjaz",
    email_address: "electricgorillatattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Bob Geerts",
    img: "artists/2020-Artists/Bob-Geerts.jpg",
    country: "Netherlands",
    town: "Eindhoven",
    location: "Eindhoven, Netherlands",
    insta_handle: "bobeustattoo",
    email_address: "info@trueblue-tattoo.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Britney Goodman",
    img: "artists/2020-Artists/Britney-Goodman.jpg",
    country: "USA",
    town: "Brooklyn",
    location: "Brooklyn NY, USA",
    insta_handle: "beexgood",
    email_address: "beexgood@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Byron Barker",
    img: "artists/2020-Artists/Byron-Barker.jpg",
    country: "South Africa",
    town: "Cape Town",
    location: "Cape Town, SA",
    insta_handle: "byronbarkertattoos",
    email_address: "byronbarker13@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Charlie Murphy",
    img: "artists/2020-Artists/Charlie-Murphy.jpg",
    country: "USA",
    town: "Portland, Maine",
    location: "Portland, Maine, USA",
    insta_handle: "charliemurphytattooer",
    email_address: "charliemurphytattooer@protonmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Chaz Otto",
    img: "artists/2020-Artists/Chaz-Otto.jpg",
    country: "USA",
    town: "Nashville, TN",
    location: "Nashville, TN, USA",
    insta_handle: "dirtbagxchaz",
    email_address: "dirtbagxchaz@yahoo.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Chris De Villiers",
    img: "artists/2020-Artists/Chris-De-Villiers.jpg",
    country: "South Africa",
    town: "Cape Town",
    location: "Cape Town, SA",
    insta_handle: "chrisd3viltattoos",
    email_address: "info@thebodyarchitects.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Clemens Hahn",
    img: "artists/2020-Artists/Clemens-Hahn.jpg",
    country: "Germany",
    town: "Mannheim",
    location: "Mannheim, Germany",
    insta_handle: "clemens_hahn",
    email_address: "clemens@trulyyourstattoo.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Courtney O'Shea",
    img: "artists/2020-Artists/Courtney-O'Shea.jpg",
    country: "USA",
    town: "Nashville",
    location: "Nashville, USA",
    insta_handle: "courtneykotattoo",
    email_address: "courtneytattoos@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  // {
  //   name: "Dan Gilsdorf",
  //   img: "artists/2020-Artists/Dan-Gilsdorf.jpg",
  //   country: "USA",
  //   town: "Portland, Oregon",
  //   location: "Portland, Oregon, USA",
  //   insta_handle: "dangilsdorf",
  //   email_address: "dan@atlastattoo.com",
  //   // style: "Traditional",
  //   year: "2020",
  // },
  {
    name: "Dan Sinnes",
    img: "artists/2020-Artists/Dan-Sinnes.jpg",
    country: "Luxembourg",
    town: "Luxembourg",
    location: "Luxembourg",
    insta_handle: "dansin",
    email_address: "dansintattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Danny Garbiras",
    img: "artists/2020-Artists/Danny-Garbiras.jpg",
    country: "Traveling",
    town: "Traveling",
    location: "Traveling",
    insta_handle: "danny_ltp13",
    email_address: "danny.tattoos13@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Darryl Hart",
    img: "artists/2020-Artists/Darryl-Bertman.jpg",
    country: "Canada",
    town: "New Brunswick",
    location: "New Brunswick, Canada",
    insta_handle: "dhart_tl",
    email_address: "hightides.dhart@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "David Pollen",
    img: "artists/2020-Artists/David-Pollen.jpg",
    country: "China",
    town: "Shanghai",
    location: "Shanghai, China",
    insta_handle: "azndavetattoo",
    email_address: "dpollen123@gmmial.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Dean Clarke",
    img: "artists/2020-Artists/Dean-Clarke.jpg",
    country: "South Africa",
    town: "Pretoria",
    location: "Pretoria, SA",
    insta_handle: "deanclarkeart",
    email_address: "tonycreations@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Dimitris Dimopoulos",
    img: "artists/2020-Artists/Dimitris-Dimopoulos.jpg",
    country: "Greece",
    town: "Greece",
    location: "Greece",
    insta_handle: "dimitris_wtltattoo",
    email_address: "wtl.tattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Dokter Irie",
    img: "artists/2020-Artists/Dokter-Irie.jpg",
    country: "South Africa",
    town: "Cape Town",
    location: "Cape Town, SA",
    insta_handle: "dokter_irie",
    email_address: "info@capeelectrictattoo.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Duan Woo",
    img: "artists/2020-Artists/Duan-Woo.jpg",
    country: "China",
    town: "Shanghai",
    location: "Shanghai, China",
    insta_handle: "duan.tattooer",
    email_address: "641697860@qq.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Elie Falcon",
    img: "artists/2020-Artists/Elie-Falcon.jpg",
    country: "USA",
    town: "Boston",
    location: "Boston, USA",
    insta_handle: "prof_falcon",
    email_address: "falcontattoos@hotmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Erik Duym",
    img: "artists/2020-Artists/Erik-Duym.jpg",
    country: "Netherlands",
    town: "Rotterdam",
    location: "Rotterdam, Netherlands",
    insta_handle: "erik_duym",
    email_address: "erikduym.tattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Esther de Miguel",
    img: "artists/2020-Artists/Esther-de-Miguel.jpg",
    country: "Germany",
    town: "Berlin",
    location: "Berlin, Germany",
    insta_handle: "estherdemiguel",
    email_address: "la_transilvana@hotmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Fernando Mardo",
    img: "artists/2020-Artists/Fernando-Mardo.jpg",
    country: "USA",
    town: "Arizona",
    location: "Arizona, USA",
    insta_handle: "fernofthewest",
    email_address: "owerart@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Francis Caraccioli",
    img: "artists/2020-Artists/Francis-Caraccioli.jpg",
    country: "USA",
    town: "California",
    location: "California, USA",
    insta_handle: "death_cloak",
    email_address: "frankiecaraccioli@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Gareth Lloyd",
    img: "artists/2020-Artists/Gareth-Lloyd.jpg",
    country: "South Africa",
    town: "Cape Town",
    location: "Cape Town, SA",
    insta_handle: "garethlloyd.cet",
    email_address: "info@capeelectrictattoo.com",
    // style: "Traditional",
    year: "2020",
  },
  // {
  //   name: "Greg Whitehead",
  //   img: "artists/2020-Artists/Greg-Whitehead.jpg",
  //   country: "USA",
  //   town: "Portland, Oregon",
  //   location: "Portland, Oregon, USA",
  //   insta_handle: "greggletron",
  //   email_address: "greggletron@gmail.com",
  //   // style: "Traditional",
  //   year: "2020",
  // },
  {
    name: "Guus Mandigers",
    img: "artists/2020-Artists/Guus-Mandigers.jpg",
    country: "Netherlands",
    town: "Eindhoven",
    location: "Eindhoven, Netherlands",
    insta_handle: "mandigerstattoo",
    email_address: "info@trueblue-tattoo.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Inez Janiak",
    img: "artists/2020-Artists/Inez-Janiak.jpg",
    country: "Poland",
    town: "Traveling",
    location: "Traveling, Poland",
    insta_handle: "ineepine",
    email_address: "inezjaniakguestspot@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Jamie Molin",
    img: "artists/2020-Artists/Jamie-Molin.jpg",
    country: "Australia",
    town: "Brisbane",
    location: "Brisbane, Australia",
    insta_handle: "fingers_dott",
    email_address: "fingers.dott@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Jay Corden",
    img: "artists/2020-Artists/Jay-Corden.jpg",
    country: "Switzerland",
    town: "Lucerne",
    location: "Lucerne, Switzerland",
    insta_handle: "jay_tatts",
    email_address: "fjay_tatts@gmx.ch",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Jarret Crosson",
    img: "artists/2020-Artists/Jarret-Crosson.jpg",
    country: "USA",
    town: "Austin, Texas",
    location: "Austin, Texas, USA",
    insta_handle: "jarretcrosson",
    email_address: "jarretcrosson@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Jason Tyler Grace",
    img: "artists/2020-Artists/Jason-Tyler-Grace.jpg",
    country: "Costa Rica",
    town: "Mal Pais",
    location: "Mal Pais, Costa Rica",
    insta_handle: "jasontylergrace",
    email_address: "jasontgrace@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  // {
  //   name: "Javier Betancourt",
  //   img: "artists/2020-Artists/Javier-Betancourt.jpg",
  //   country: "USA",
  //   town: "Miami, Florida",
  //   location: "Miami, Florida, USA",
  //   insta_handle: "javierbetancourt",
  //   email_address: "ochoplacastattoo@gmail.com",
  //   // style: "Traditional",
  //   year: "2020",
  // },
  {
    name: "Jean le Roux",
    img: "artists/2020-Artists/Jean-Le-Roux.jpg",
    country: "UK",
    town: "London",
    location: "London, UK",
    insta_handle: "jeanleroux",
    email_address: "jeanleroux86@icloud.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Jimmy Shy",
    img: "artists/2020-Artists/Jimmy-Shy.jpg",
    country: "Taiwan",
    town: "Taipei",
    location: "Taipei, Taiwan",
    insta_handle: "jimmyshy",
    email_address: "jimmyshy@hellotattoostudio.com",
    // style: "Traditional",
    year: "2020",
  },
  // {
  //   name: "Joe Ellis",
  //   img: "artists/2020-Artists/Joe-Ellis.jpg",
  //   country: "United Kingdom",
  //   town: "Leeds",
  //   location: "Leeds, UK",
  //   insta_handle: "joeellistattoo",
  //   email_address: "sacredelectrictattoo@gmail.com",
  //   // style: "Traditional",
  //   year: "2020",
  // },
  {
    name: "Jon Garber",
    img: "artists/2020-Artists/Jon-Garber.jpg",
    country: "USA",
    town: "Scotssdale",
    location: "Scotssdale, USA",
    insta_handle: "deadeyenone",
    email_address: "",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Jonathan Reina",
    img: "artists/2020-Artists/Jonathan-Reina.jpg",
    country: "Spain",
    town: "Gran Canaria",
    location: "Gran Canaria, Spain",
    insta_handle: "jonathanreina",
    email_address: "jonathanreinatattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  // {
  //   name: "Josh Todaro",
  //   img: "artists/2020-Artists/Josh-Todaro.jpg",
  //   country: "Australia",
  //   town: "Melbourne",
  //   location: "Melbourne, Australia",
  //   insta_handle: "josh_todaro",
  //   email_address: "joshtodaro@gmail.com",
  //   // style: "Traditional",
  //   year: "2020",
  // },
  {
    name: "Justin Rockett",
    img: "artists/2020-Artists/Justin-Rockett.jpg",
    country: "United Kingdom",
    town: "Leeds",
    location: "Leeds, UK",
    insta_handle: "rockett_tattoo",
    email_address: "sacredelectrictattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Kady Wilie",
    img: "artists/2020-Artists/Kady-Wilie.jpg",
    country: "USA",
    town: "Austin, Texas",
    location: "Austin, Texas, USA",
    insta_handle: "kadyannetattoo",
    email_address: "kxatattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Kai Soong",
    img: "artists/2020-Artists/Kai-Song.jpg",
    country: "China",
    town: "Shanghai",
    location: "Shanghai, China",
    insta_handle: "kai.tattooer",
    email_address: "sk15831353818@163.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Katy Raymon",
    img: "artists/2020-Artists/Katy-Raymond.jpg",
    country: "USA",
    town: "Saint Louis, Missouri",
    location: "Saint Louis, Missouri, USA",
    insta_handle: "katy_did_it",
    email_address: "tattoos@katydidthat.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Kawika Montoya",
    img: "artists/2020-Artists/Kawika-Montoya.jpg",
    country: "Costa Rica",
    town: "Mal Pais",
    location: "Mal Pais, Costa Rica",
    insta_handle: "k_montoya_tattoo",
    email_address: "kawikamontoya@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Laceyrea Barrett",
    img: "artists/2020-Artists/Laceyrea-Barret.jpg",
    country: "USA",
    town: "Portland, Maine",
    location: "Portland, Maine, USA",
    insta_handle: "laceyreabarrett",
    email_address: "laceyreatattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Lorin Devine",
    img: "artists/2020-Artists/Lorin-Devine.jpg",
    country: "USA",
    town: "",
    location: "USA",
    insta_handle: "lorindevine",
    email_address: "lorin@lorindevine.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Lucas Wagner",
    img: "artists/2020-Artists/Lucas-Wager.jpg",
    country: "Germany",
    town: "Mannheim",
    location: "Mannheim, Germany",
    insta_handle: "wastedhappyyouth",
    email_address: "tattoo.lucaswagner@outlook.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Luke James Smith",
    img: "artists/2020-Artists/Luke-James-Smith.jpg",
    country: "United Kingdom",
    town: "London",
    location: "London, UK",
    // style: "Traditional",
    insta_handle: "lukesmithtattoo",
    email_address: "lukesmithtattoo@gmail.com",
    year: "2020",
  },
  {
    name: "Luke Nicou",
    img: "artists/2020-Artists/Luke-Nicou.jpg",
    country: "South Africa",
    town: "Port Elizabeth",
    location: "Port Elizabeth, SA",
    insta_handle: "luckyluketattooer",
    email_address: "luckyluketattooer@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Luke Satoru",
    img: "artists/2020-Artists/Luke-Satoru.jpg",
    country: "Thailand",
    town: "Bangkok",
    location: "Bangkok, Thailand",
    insta_handle: "luke_satoru",
    email_address: "blackpigstudiobkk@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Lydia Amor",
    img: "artists/2020-Artists/Lydia-Amor.jpg",
    country: "United Kingdom",
    town: "Leicester",
    location: "Leicester, UK",
    insta_handle: "tattooingbyamor",
    email_address: "lydiaamortattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Matthew Oldfield",
    img: "artists/2020-Artists/Matthew-Oldfield.jpg",
    country: "SA",
    town: "Cape Town",
    location: "Cape Town, SA",
    // style: "Traditional",
    insta_handle: "mattoldfield_tattoos",
    email_address: "matthew.a.oldfield@gmail.com",
    year: "2020",
  },
  {
    name: "Matt Cannon",
    img: "artists/2020-Artists/Matt-Cannon.jpg",
    country: "USA",
    town: "Anaheim",
    location: "Anaheim, USA",
    // style: "Traditional",
    insta_handle: "mattxcannon",
    email_address: "fadetoblacktattoo@yahoo.com",
    year: "2020",
  },
  {
    name: "Mav Mess",
    img: "artists/2020-Artists/Mav-Mess.jpg",
    country: "USA",
    town: "Vancouver, Washington",
    location: "Vancouver, Washington, USA",
    insta_handle: "mavmess",
    email_address: "deluxemav@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Max May",
    img: "artists/2020-Artists/Max-May-2.jpg",
    country: "USA",
    town: "Santa Rosa",
    location: "California, USA",
    insta_handle: "maxmaytattoo",
    email_address: "maxmaytattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Melanie No",
    img: "artists/2020-Artists/Melanie-No.jpg",
    country: "Germany",
    town: "Frankfurt",
    location: "Frankfurt, Germany",
    insta_handle: "_melanie_no_",
    email_address: "tattoo@inkyundstretchy.de",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Mike Love",
    img: "artists/2020-Artists/Mike-Love.jpg",
    country: "UK",
    town: "Leicester",
    location: "Leicester, UK",
    insta_handle: "mikelovetattooer",
    email_address: "mike@mrmikelove.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Mo Ducommun",
    img: "artists/2020-Artists/Mo-Ducommun.jpg",
    country: "Switzerland",
    town: "Lucerne",
    location: "Lucerne, Switzerland",
    insta_handle: "moducommun",
    email_address: "moeducommun@gmailc.om",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Molly Pehle",
    img: "artists/2020-Artists/Molly-Pehle.jpg",
    country: "USA",
    town: "Overland",
    location: "Missouri, USA",
    insta_handle: "mollypehletattoo",
    email_address: "mollypehletattooer@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Monique La Cucaracha",
    img: "artists/2020-Artists/Monique-La-Cucaracha.jpg",
    country: "Switzerland",
    town: "Zurich",
    location: "Zurich, Switzerland",
    insta_handle: "moniquelacucaracha",
    email_address: "monique.l@gmx.ch",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Nic Lewis",
    img: "artists/2020-Artists/Nic-Lewis.jpg",
    country: "South Africa",
    town: "Durban",
    location: "Durban, SA",
    insta_handle: "nic.fury",
    email_address: "finkdafoe@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Nicholas Mudskipper",
    img: "artists/2020-Artists/Nicholas-Mudskipper.jpg",
    country: "South Africa",
    town: "Cape Town",
    location: "Cape Town, SA",
    // style: "Traditional",
    insta_handle: "nicholasmudskipper",
    email_address: "nicholaskeeping@gmail.com",
    year: "2020",
  },
  {
    name: "Nikki Vasquez",
    img: "artists/2020-Artists/Nikki-Vasques.jpg",
    country: "New York / Austria",
    town: "New York / Austria",
    location: "New York / Austria",
    // style: "Traditional",
    insta_handle: "nikki.vasquez",
    email_address: "narf830@oal.com",
    year: "2020",
  },
  {
    name: "Panagiotis Ntolas",
    img: "artists/2020-Artists/Panos-Ntolas.jpg",
    country: "Greece",
    town: "",
    location: "Greece",
    insta_handle: "panosntolas",
    email_address: "panosntolas@hotmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Paul Wolk",
    img: "artists/2020-Artists/Paul-Wolk.jpg",
    country: "Canada",
    town: "Toronto",
    location: "Toronto, Canada",
    insta_handle: "paulwolk_tl",
    email_address: "blackeyeghost@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Pepi Dimevski",
    img: "artists/2020-Artists/Pepi-Dimevski.jpg",
    country: "South Africa",
    town: "Johannesburg",
    location: "Johannesburg, SA",
    insta_handle: "sa_hardcore_socialclub",
    email_address: "sahardcoretattoos@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  // {
  //   name: "Pyramid Mark",
  //   img: "artists/2020-Artists/Paul-Wolk.jpg",
  //   country: "USA",
  //   town: "California",
  //   location: "California, USA",
  //   insta_handle: "pyramidtattoo",
  //   // style: "Traditional",
  //   year: "2020",
  // },
  {
    name: "Richard Phipson",
    img: "artists/2020-Artists/Richard-Phipson.jpg",
    country: "Hong Kong",
    town: "",
    location: "Hong Kong",
    insta_handle: "iconoglyphs",
    email_address: "info@starcrossedtattoo.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Richie Howes",
    img: "artists/2020-Artists/Richie-Howes.jpg",
    country: "South Africa",
    town: "Shelly Beach, KZN",
    location: "Shelly Beach, KZN, SA",
    insta_handle: "richietattooer",
    email_address: "richietattooer@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Ross Hallam",
    img: "artists/2020-Artists/Ross-Hallam.jpg",
    country: "South Africa",
    town: "Johannesburg",
    location: "Johannesburg, SA",
    insta_handle: "rosshallam",
    email_address: "handstyletattoos@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Rudolf Maartens",
    img: "artists/2020-Artists/Rudolf-Maartins.jpg",
    country: "Netherlands",
    town: "Rotterdam",
    location: "Rotterdam, Netherlands",
    insta_handle: "om.karma.tattoo",
    email_address: "triangulumnigrum@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Ryan Cooper Thompson",
    img: "artists/2020-Artists/Ryan-Cooper-Thompson.jpg",
    country: "USA",
    town: "",
    location: "USA",
    insta_handle: "ryancooperthompson",
    email_address: "ryan@ryancooperthompson.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Sarah Herzdame",
    img: "artists/2020-Artists/Sarah-Herzdame.jpg",
    country: "Germany",
    town: "Berlin",
    location: "Berlin, Germany",
    insta_handle: "herzdame",
    email_address: "herzdame@tilldth.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Shaun Dean",
    img: "artists/2020-Artists/Shaun-Dean.jpg",
    country: "South Africa",
    town: "Cape Town",
    location: "Cape Town, SA",
    insta_handle: "shaunjdean",
    email_address: "shaundeanappointments@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Sebastian Espinoza",
    img: "artists/2020-Artists/Sebastian-Espinoza.jpg",
    country: "Country",
    town: "Town",
    location: "Town, Country",
    insta_handle: "sebaespitattoo",
    email_address: "sebacauttattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Squid Yu",
    img: "artists/2020-Artists/Squid-Yu.jpg",
    country: "Taiwan",
    town: "",
    location: "Taiwan",
    insta_handle: "squid_yu",
    email_address: "squid.yu.tattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Stara Broda",
    img: "artists/2020-Artists/Stara-Broda.jpg",
    country: "Traveling",
    town: null,
    location: "Traveling ",
    insta_handle: "starabroda",
    email_address: "starabroda@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Stefan Guntzel",
    img: "artists/2020-Artists/Stefan-Guntzel.jpg",
    country: "Switzerland",
    town: "St. Gallen",
    location: "St. Gallen, Switzerland",
    insta_handle: "stefan_guentzel",
    email_address: "info@sgtattoo.ch",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Suzanna Fisher",
    img: "artists/2020-Artists/Suzanna-Fisher.jpg",
    country: "USA",
    town: "Seattle",
    location: "Seattle",
    insta_handle: "suzannafisher",
    email_address: "bellwethertattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Tanner Ramsey",
    img: "artists/2020-Artists/Tanner-Ramsey.jpg",
    country: "USA",
    town: "Ventura, California",
    location: "Ventura, California, USA",
    insta_handle: "tannerramsey",
    email_address: "tramseykr@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Tarzan",
    img: "artists/2020-Artists/Tarzan.jpg",
    country: "SA",
    town: "Durban",
    location: "Durban, SA",
    insta_handle: "txrzxn",
    email_address: "tznkzn@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Taryn Nissen",
    img: "artists/2020-Artists/Taryn-Nissen.jpg",
    country: "South Africa",
    town: "Johannesburg",
    location: "Johannesburg, SA",
    insta_handle: "tarynthetattooist",
    email_address: "tarynnissenartist@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Theunis Coetzee",
    img: "artists/2020-Artists/Theunis-Coetzee.jpg",
    country: "South Africa",
    town: "Johannesburg",
    location: "Johannesburg, SA",
    insta_handle: "theunisawhe187",
    email_address: "info@awetattoos.co.za",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Thomas Burkhardt",
    img: "artists/2020-Artists/Thomas-Burkhardt.jpg",
    country: "Germany",
    town: "Berlin",
    location: "Berlin, Germany",
    insta_handle: "thomasburkhardt",
    email_address: "t.burkhardt@gmx.de",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Tony Weintraud",
    img: "artists/2020-Artists/Tony-Weintraud.jpg",
    country: "South Africa",
    town: "Pretoria",
    location: "Pretoria, SA",
    insta_handle: "onetattootony",
    email_address: "tonycreations@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Thys Uys",
    img: "artists/2020-Artists/Thys-Uys.jpg",
    country: "SA",
    town: "Johannesburg",
    location: "Johannesburg, SA",
    insta_handle: "thys_uys",
    email_address: "thys@fallenheroes.co.za",
    // style: "Traditional",
    year: "2020",
  },
  // {
  //   name: "Trevor Taylor",
  //   img: "artists/2020-Artists/Trevor-Taylor.jpg",
  //   country: "USA",
  //   town: "Seattle",
  //   location: "Seattle, USA",
  //   insta_handle: "cooltrev",
  //   email_address: "liberty.tattoo.seattle@gmail.com",
  //   // style: "Traditional",
  //   year: "2020",
  // },
  {
    name: "Tyler Abner",
    img: "artists/2020-Artists/Tyler-Abner.jpg",
    country: "USA",
    town: "Cincinnati",
    location: "Cincinnati, USA",
    insta_handle: "tylerabnertattoo",
    email_address: "ghostpanthertattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Valentin Tatau",
    img: "artists/2020-Artists/Valentin-Tatau.jpg",
    country: "Germany",
    town: "Berlin",
    location: "Berlin, Germany",
    insta_handle: "valentin.tatau",
    email_address: "vtatauierung@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Vic James",
    img: "artists/2020-Artists/Vic-James.jpg",
    country: "Australia",
    town: "Melbourne",
    location: "Melbourne, Australia",
    insta_handle: "vic_james_",
    email_address: "vic.james.tattooer@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Victor B Pastier",
    img: "artists/2020-Artists/Victor-B-Pastier.jpg",
    country: "France",
    town: "",
    location: "France",
    insta_handle: "victorbpastier",
    email_address: "victorbptattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Vincent Riquier",
    img: "artists/2020-Artists/Vincent-Riquier.jpg",
    country: "USA",
    town: "Richmond, Virginia",
    location: "Richmond, Virginia, USA",
    insta_handle: "deathwitchtattoo",
    email_address: "deathwitchtattoo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Waldo Del Rocca",
    img: "artists/2020-Artists/Waldo-Del-Rocca.jpg",
    country: "South Africa",
    town: "Cape Town",
    location: "Cape Town, SA",
    // style: "Traditional",
    insta_handle: "waldodelrocca",
    email_address: "info@capeelectrictattoo.com",
    year: "2020",
  },
  {
    name: "Willem Jansen",
    img: "artists/2020-Artists/Willem-Jansen.jpg",
    country: "Netherlands",
    town: "Eindhoven",
    location: "Eindhoven, Netherlands",
    insta_handle: "willem.jansen.tattoo",
    email_address: "wtjansen@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
  {
    name: "Yadou Trojanowska",
    img: "artists/2020-Artists/Yadou-Trojanowska.jpg",
    country: "Poland",
    town: "Warsaw",
    location: "Warsaw, Poland",
    insta_handle: "yadou_tattoo",
    email_address: "yadouinfo@gmail.com",
    // style: "Traditional",
    year: "2020",
  },
]

export default PAST_ARTISTS_DATA
