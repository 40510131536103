import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Artists from "../components/Artists"

const ArtistPage = () => {
  return (
    <Layout>
      <SEO title="Artists" />
      <Artists />
    </Layout>
  )
}

export default ArtistPage
